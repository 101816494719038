import { RefreshAccessTokenMutation } from '$/graphql/generated';
import REFRESH_ACCESS_TOKEN_MUTATION from '$/graphql/mutations/auth/refreshAccessToken';
import {
  removeAccessToken,
  removeRefreshToken,
  saveAccessToken,
  saveRefreshToken,
} from '$/graphql/storage/tokens';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import globalAlternateStates from '../../variables/error';

export const handleRefreshTokenResponse = (
  data: RefreshAccessTokenMutation | null | undefined,
) => {
  // if the response is valid, we save the tokens
  if (data && data.refreshAccessToken.__typename === 'AuthenticationPayload') {
    saveAccessToken(data.refreshAccessToken.accessToken);
    saveRefreshToken(data.refreshAccessToken.refreshToken);
    // if the response is invalid, we set the global state to have an invalid refresh token
  } else {
    globalAlternateStates({
      invalidRefreshToken: true,
    });
    removeRefreshToken();
    removeAccessToken();
  }
};

export default function useRefreshAccessToken() {
  const [createRefreshAccessTokenMutation, { error, loading }] = useMutation(
    REFRESH_ACCESS_TOKEN_MUTATION,
  );

  const refreshAccessTokenMutation = useCallback(
    async (refreshToken: string) => {
      const response = await createRefreshAccessTokenMutation({
        variables: {
          refreshToken,
        },
        context: {
          debounceKey: 'refreshAccessToken',
        },
      });

      handleRefreshTokenResponse(response.data);
    },
    [createRefreshAccessTokenMutation],
  );

  return { refreshAccessTokenMutation, error, loading };
}
